import { ReactNode, createContext, useReducer } from 'react';
// hooks

import {
  getAccounts,
  AccountsSummary,
  WalletDto,
  WalletByDayDto,
  CoinDto,
  CoinByDayDto,
  ActionMap,
  getAccountBalances,
  getAccountsSummary,
  getTokenBalances,
  getWalletBalances,
} from '@/api/accounts';

import { getIncomeExpenseCategories, IncomeExpenseData } from '@/api/accounting';
import { getGainLossAsset, getGainLossAccount } from '@/api/reports';

import BigNumber from 'bignumber.js';
import { message } from 'antd';
import { get } from 'lodash-es';
import { addBalanceDataToAccounts } from '../utils/dataOperations';

export type ReportContextType = {
  isLoading: boolean;
  accounts: WalletDto[];
  coinsBalances: CoinDto[];
  accountsByDay: WalletByDayDto[];
  accountBalancesByDay: CoinByDayDto[];
  accountsSummary: AccountsSummary;
  incomeData: IncomeExpenseData[];
  expenseData: IncomeExpenseData[];
  gainLossAssets: any[];
  gainLossAccounts: any[];
  onGetAccounts: Function;
  onGetTokens: Function;
  onGetSummary: Function;
  onGetAccountsByDay: Function;
  onGetTokensByDay: Function;
  onGetIncomeExpenseCategories: Function;
  onGetGainLossAssets: Function;
  onGetGainLossAccounts: Function;
};

export type ReportContextProps = {
  isLoading: boolean;
  accounts: WalletDto[];
  coinsBalances: CoinDto[];
  accountsByDay: WalletByDayDto[];
  accountBalancesByDay: CoinByDayDto[];
  accountsSummary: AccountsSummary;
  incomeData: IncomeExpenseData[];
  expenseData: IncomeExpenseData[];
  gainLossAssets: any[];
  gainLossAccounts: any[];
};

const initialState: ReportContextProps = {
  isLoading: false,
  accounts: [],
  coinsBalances: [],
  accountsByDay: [],
  accountBalancesByDay: [],
  accountsSummary: {
    totalBalance: '',
    unrealizedReturn: '',
    unrealizedReturnPercent: '',
  },
  incomeData: [],
  expenseData: [],
  gainLossAssets: [],
  gainLossAccounts: [],
};

enum Types {
  startLoading = 'startLoading',
  accounts = 'accounts',
  coinsBalances = 'coinsBalances',
  accountsByDay = 'accountsByDay',
  accountBalancesByDay = 'accountBalancesByDay',
  accountsSummary = 'accountsSummary',
  incomeCategories = 'incomeCategories',
  expenseCategories = 'expenseCategories',
  assetIds = 'assetIds',
  gainLossAssets = 'gainLossAssets',
  gainLossAccounts = 'gainLossAccounts',
}

type ReportPayload = {
  [Types.startLoading]: {
    isLoading: boolean;
  };
  [Types.accounts]: {
    accounts: WalletDto[];
    isLoading: boolean;
  };
  [Types.coinsBalances]: {
    coinsBalances: CoinDto[];
    isLoading: boolean;
  };
  [Types.accountsByDay]: {
    accountsByDay: WalletByDayDto[];
    isLoading: boolean;
  };
  [Types.accountBalancesByDay]: {
    accountBalancesByDay: CoinByDayDto[];
    isLoading: boolean;
  };
  [Types.accountsSummary]: {
    accountsSummary: AccountsSummary;
    isLoading: boolean;
  };
  [Types.incomeCategories]: {
    incomeData: IncomeExpenseData[];
    isLoading: boolean;
  };
  [Types.expenseCategories]: {
    expenseData: IncomeExpenseData[];
    isLoading: boolean;
  };
  [Types.gainLossAssets]: {
    gainLossAssets: any[];
    isLoading: boolean;
  };
  [Types.gainLossAccounts]: {
    gainLossAccounts: any[];
    isLoading: boolean;
  };
};

type ReportsActions = ActionMap<ReportPayload>[keyof ActionMap<ReportPayload>];

const ReportContext = createContext<ReportContextType | null>(null);

const reducer = (state: ReportContextProps, action: ReportsActions) => {
  const { isLoading } = action.payload as ReportContextProps;

  switch (action.type) {
    case Types.startLoading:
      return {
        ...state,
        isLoading,
      };
    case Types.accounts:
      const { accounts } = action.payload;
      return {
        ...state,
        accounts,
        isLoading,
      };

    case Types.coinsBalances:
      const { coinsBalances } = action.payload;
      return {
        ...state,
        coinsBalances,
        isLoading,
      };

    case Types.accountsSummary:
      const { accountsSummary } = action.payload;
      return {
        ...state,
        accountsSummary,
        isLoading,
      };

    case Types.accountsByDay:
      const { accountsByDay } = action.payload;
      return {
        ...state,
        accountsByDay,
        isLoading,
      };

    case Types.accountBalancesByDay:
      const { accountBalancesByDay } = action.payload;
      return {
        ...state,
        accountBalancesByDay,
        isLoading,
      };

    case Types.incomeCategories:
      const { incomeData } = action.payload;
      return {
        ...state,
        incomeData,
        isLoading,
      };

    case Types.expenseCategories:
      const { expenseData } = action.payload;
      return {
        ...state,
        expenseData,
        isLoading,
      };
    case Types.gainLossAssets:
      const { gainLossAssets } = action.payload;
      return {
        ...state,
        gainLossAssets,
        isLoading,
      };
    case Types.gainLossAccounts:
      const { gainLossAccounts } = action.payload;
      return {
        ...state,
        gainLossAccounts,
        isLoading,
      };
    default:
      return state;
  }
};

type BalanceReportProps = {
  children: ReactNode;
};

function ReportProvider({ children }: BalanceReportProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onSetLoadingState = (state: boolean) => {
    dispatch({
      type: Types.startLoading,
      payload: { isLoading: state },
    });
  };

  const onGetAccounts = () => {
    onSetLoadingState(true);
    getAccounts()
      .then(({ body }) => {
        const accountsWithAvailableBalances = body.data.map((d: any) => {
          d.availableBalance = null;
          if (d.balances && d.balances?.length !== 0) {
            d.availableBalance = d.balances.reduce(
              (accum: number, balance: any) =>
                accum + new BigNumber(balance.fiatAmount ?? '0').toNumber(),
              0,
            );
          }
          return d;
        });

        addBalanceDataToAccounts(accountsWithAvailableBalances);
        dispatch({
          type: Types.accounts,
          payload: { accounts: accountsWithAvailableBalances || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetSummary = (value: string) => {
    onSetLoadingState(true);
    getAccountsSummary({})
      .then(({ body }) => {
        dispatch({
          type: Types.accountsSummary,
          payload: { accountsSummary: body.data || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetTokens = (includeArchiveSectionAccounts = true) => {
    onSetLoadingState(true);
    getAccountBalances({ includeArchiveSectionAccounts })
      .then(({ body }) => {
        dispatch({
          type: Types.coinsBalances,
          payload: {
            coinsBalances:
              body.data.map((d: any) => {
                d.totalPrice = new BigNumber(d.totalPrice).toNumber();

                return d;
              }) || null,
            isLoading: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetAccountsByDay = (formattedDate: string, includeArchiveSectionAccounts = true) => {
    onSetLoadingState(true);
    getWalletBalances({
      from: formattedDate,
      to: formattedDate,
      includeArchiveSectionAccounts,
    })
      .then(({ body }) => {
        const _accountsByDay = get(body.data, '0.accounts');
        addBalanceDataToAccounts(_accountsByDay, true);
        dispatch({
          type: Types.accountsByDay,
          payload: { accountsByDay: _accountsByDay || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);

        message.error('Something went wrong');
      });
  };

  const onGetTokensByDay = (formattedDate: string, includeArchiveSectionAccounts = true) => {
    onSetLoadingState(true);
    getTokenBalances({
      from: formattedDate,
      to: formattedDate,
      includeArchiveSectionAccounts,
    })
      .then(({ body }) => {
        dispatch({
          type: Types.accountBalancesByDay,
          payload: { accountBalancesByDay: get(body.data, '0.tokens') || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetGainLossAssets = (
    symbolFilter: string[],
    startDateFilter: string,
    endDateFilter: string,
  ) => {
    onSetLoadingState(true);
    getGainLossAsset({
      tokenIds: symbolFilter ? symbolFilter : undefined,
      from: startDateFilter,
      to: endDateFilter,
    })
      .then(({ body }) => {
        dispatch({
          type: Types.gainLossAssets,
          payload: { gainLossAssets: body.data || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetGainLossAccounts = (
    accountIdFilter: string[],
    symbolFilter: string[],
    startDateFilter: string,
    endDateFilter: string,
  ) => {
    onSetLoadingState(true);
    getGainLossAccount({
      accounts: accountIdFilter ? accountIdFilter : undefined,
      tokenIds: symbolFilter ? symbolFilter : undefined,
      from: startDateFilter,
      to: endDateFilter,
    })
      .then(({ body }) => {
        dispatch({
          type: Types.gainLossAccounts,
          payload: { gainLossAccounts: body.data || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  const onGetIncomeExpenseCategories = (
    accountIdFilter: string,
    tokenIdFilter: number,
    startDateFilter: string,
    endDateFilter: string,
  ) => {
    onSetLoadingState(true);
    getIncomeExpenseCategories({
      accountId: accountIdFilter ? accountIdFilter : undefined,
      tokenId: tokenIdFilter ? tokenIdFilter : undefined,
      from: startDateFilter,
      to: endDateFilter,
    })
      .then(({ body }) => {
        dispatch({
          type: Types.incomeCategories,
          payload: { incomeData: get(body, 'data.income') || [], isLoading: false },
        });
        dispatch({
          type: Types.expenseCategories,
          payload: { expenseData: get(body, 'data.expense') || null, isLoading: false },
        });
      })
      .catch((err) => {
        console.log(err);
        message.error('Something went wrong');
      });
  };

  return (
    <ReportContext.Provider
      value={{
        ...state,
        onGetSummary,
        onGetAccounts,
        onGetTokens,
        onGetAccountsByDay,
        onGetTokensByDay,
        onGetIncomeExpenseCategories,
        onGetGainLossAssets,
        onGetGainLossAccounts,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
}

export { ReportProvider, ReportContext };
